

.icon:hover {
    transform: translateY(-5px) scale(1.2); /* Slight upward movement and increase in size */
}

.xicon{
    color: white;
    padding : 0.35rem;
}
.xicon-dark{
  color: black;
  padding : 0.35rem;
}

.contact-us-btn-footer {
    padding: 0.8rem 2rem;
    background: #d9481c !important; /* Bright blue background */
    color: white !important;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
    /* animation: fadeInButton 2s ease-in-out forwards; */
    margin-top: 1rem; /* Ensure a gap between the two buttons */
    margin-left: 1rem;
}

.contact-us-btn-footer:hover {
    transform: scale(1.1);
  }
.contact-us-btn-footer.dark-mode:hover{
  background-color: white !important;
  color: black !important;
}
.contact-us-btn-footer.light-mode:hover{
  background-color: black !important; 
  color: white !important;
}

  .custom-tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .custom-tooltip {
    position: absolute;
    bottom: 80%;
    left: 55%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.9rem;
    white-space: nowrap;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .custom-tooltip::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
  
  .tooltip-arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
  }


  .footer-light{
    background-color: white;
    color: black;
  }
  .footer-dark{
    background-color: black;
    color: white;
  }

/* Floating WhatsApp icon on the right bottom */
.whatsapp-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #25d366;
  font-size: 35px;
  transition: transform 0.3s ease;
}

