.youtube-icon {
  color: #FF0000 !important; /* Default color */
  /* font-size: 24px; */
  transition: transform 0.3s ease, color 0.3s ease;
  display: block; /* Allow transform to work */
}

.youtube-icon:hover {
    transform: translateY(-3px) scale(1.2) !important; /* Makes the icon grow on hover */
    color: #cd0000 !important; /* Changes the color on hover */
    cursor: pointer;
}

.youtube-link{
  padding: 0 !important;
  /* vertical-align: middle; */
  margin: auto;
  font-size: 1.5em;
}

table th, table td {
  padding: 10px;
}
problem-title{
  min-width: 200px !important;
}

table th {
  white-space: nowrap; /* Prevent wrapping */
}
.problem-id {
  min-width: 70px;
  white-space: nowrap; /* Prevent wrapping */
}

.table-container{
  padding: 0 5px 5px 5px;
}
/* Make sure the problem title gets enough space */
.table .problem-title {
  width: 45%; /* Allow the problem title to take up more space */
  min-width: 200px;
}


table th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}


table {
    font-family: 'Arial', sans-serif;
    /* font-size: 15px; */
}
table {
  border-collapse: collapse;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
table{
  box-shadow: 0 2px 4px rgba(169, 169, 169, 0.594);
}


.table-container {
  overflow-x: auto; 
}

.table th, .table td {
  padding: 0.5rem; 
}

.table th {
  text-align: left;
}

.table td {
  word-wrap: break-word; 
}


table tr:hover a {
  /* color: black ;  */
  /* font-weight: bold; */
  text-decoration: underline;
}

table td a {
  display: block; /* Make the link take up the full width of the table cell */
  text-decoration: none; /* Optional: Remove underline */
}



/* style css for checkbox  */
.fancy-checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 18px;
  line-height: 18px;
  user-select: none;
  vertical-align: middle;
}

.fancy-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  transition: all 0.3s ease;
  display: inline-block;
}
.dark-theme .checkmark{
  background-color: rgb(96, 96, 96);
}

.fancy-checkbox:hover .checkmark {
  background-color: #cfcfcf;
}

.fancy-checkbox input:checked ~ .checkmark {
  background-color: #4caf50;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.fancy-checkbox input:checked ~ .checkmark:after {
  display: block;
}




/* dark theme */

/* DSASheet.css */

.dark-theme .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05); /* Slightly darker background for striped rows */
}

.dark-theme .form-control,
.dark-theme .form-select {
  background-color: #3b3f45; /* Darker background for form elements */
  border-color: #555; /* Darker border color */
  color: #e0e0e0;
}

.dark-theme .input-group-text{
  background-color: #3b3f45;
  border-color: #555;
  color: #e0e0e0;
}

.dark-theme .btn-dark{
  background-color: #3b3f45;
  border-color: #555;
  color: #e0e0e0;
}

.dark-theme .btn-outline-secondary.btn-dark {
  color: #e0e0e0; /* White text for contrast */
  border-color: #555; /* Slightly lighter border */
}

.dark-theme .btn-outline-secondary.btn-dark:hover {
  background-color: #444; /* Darker background on hover */
  border-color: #666; /* Slightly darker border on hover */
}

.dark-theme .progress {
  background-color: #444; /* Darker progress bar background */
}

.dark-theme a {
  color: #61dafb; 
}

.dark-theme a:hover {
  color: #2196f3; /* Example hover link color */
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}

.pagination-btn {
  /* padding: 6px 12px; */
  padding-bottom: 10px;
  font-size: 1.2rem;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}

.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-text {
  font-size: 1rem;
  font-weight: bold;
}

/* Light & Dark Mode Support */
.leaderboard-modal.light-mode {
  background: rgba(255, 255, 255, 0.9);
  color: #333;
  border-radius: 12px;
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.leaderboard-modal.dark-mode {
  background: rgba(0, 0, 0, 0.85);
  color: #fff;
  border-radius: 12px;
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.2);
}

/* Close Button (Top Right) */
.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.close-icon:hover {
  color: red;
}

/* Title Styling */
.leaderboard-title {
  font-size: 1.5rem;
  font-weight: bold;
}

/* Leaderboard Button */
.leaderboard-btn {
  font-size: 1.5rem;
  text-decoration: none;
  cursor: pointer;
  /* background-color: #d9481c ; */
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: none;
  transition: all 0.3s ease-in-out;
}

.leaderboard-btn:hover {
  transform: scale(1.2);
  /* background-color: #d9481c; */
}

/* Disclaimer Note */
.disclaimer {
  font-size: 0.9rem;
  color: #d40505	;
  font-weight: bold;
  background: rgba(255, 72, 0, 0.2);
  padding: 8px;
  border-radius: 5px;
}


/* No Data Message */
.no-data {
  font-size: 1.2rem;
  font-weight: bold;
  color: red;
}

/* Leaderboard Container */
.leaderboard-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px;
}
.leaderboard{
  background-color: #111;
}
/* Leaderboard Cards */
.leaderboard-card {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  gap: 1rem;
  /* box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.1); */
}


/* Rank Number or Medal */
.leaderboard-rank {
  font-size: 1.6rem;
  font-weight: bold;
  /* margin-right: 15px; */
}

/* Profile Picture */
.leaderboard-profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid white;
  /* margin-right: 15px; */
}

/* Improved Close Button */
.close-btn {
  font-size: 1.1rem;
  padding: 8px 15px;
  transition: all 0.3s ease-in-out;
}

.close-btn:hover {
  transform: scale(1.1);
  background: rgba(255, 0, 0, 0.8);
}

/* Name & Score Styling */
.leaderboard-info {
  flex-grow: 1;
}

.leaderboard-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.leaderboard-score {
  font-size: 1rem;
  color: #ccc;
}


/* Fully Centered & Responsive Modal */
.leaderboard-modal .modal-dialog {
  max-width: 500px;
  margin: 50px auto;

}

@media (max-width: 768px) {
  .leaderboard-modal .modal-dialog {
    max-width: 80%;
    
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .leaderboard-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    gap: 0rem;
  }

  .leaderboard-rank {
    margin-bottom: 5px;
  }

  .leaderboard-profile-pic {
    margin-bottom: 10px;
  }

}


