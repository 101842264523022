/* GoogleLogin.css */

/* Base styles (Light Theme) */
.google-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background: linear-gradient(135deg, #f0d7c1, #ffffff, #fef9ea, #fae4e2);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.google-login-card {
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 90%;
  animation: fadeIn 1.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.google-login-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #333;
}

.google-login-desc {
  font-size: 14px;
  margin-bottom: 25px;
  color: #666;
  line-height: 1.5;
}

.google-login-btn {
  background-color: #4285f4; /* Google Blue */
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 15px 25px;
  border: none;
  border-radius: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.google-login-btn:hover {
  background-color: #357ae8;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.google-icon {
  margin-right: 10px;
  font-size: 22px;
}



/* Dark Theme Styles */
.google-login-container.dark-theme {
  background: linear-gradient(135deg, black, rgb(61, 61, 61), rgb(25, 25, 25), #272727);
  color: #eee;
}

.google-login-card.dark-theme-card {
  background-color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.547);
}

.google-login-title.dark-theme-title {
  color: #eee;
}

.google-login-desc.dark-theme-description {
  color: #ccc;
}

.google-login-btn.dark-theme-button {
  background-color: #3f51b5;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.dark-theme-button:hover {
  background-color: #303f9f;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.dark-theme-icon {
    color: white;
}