
.main-content.baner{
  width: 100% !important;  /* Override fixed width */
  max-width: none !important; /* Remove inherited constraints */
}
.custom-img {
  max-width: 300px;  /* Reduced size */
  height: auto;
  border-radius: 15px;  /* Rounded corners */
  /* border: 3px solid orangered;  */
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);  /* Soft shadow */
  object-fit: cover;  /* Ensures proper scaling */
}


.banner-left {
  /* animation: fadeInTop 2s ease-in-out; */
  width: 100%;
  min-width: 322px;
  /* border:2px solid green; */
  box-sizing: border-box;
  padding: 2rem 0rem;
}

.banner-right{
  /* border:2px solid red; */
  min-width: 250px;
  width: 100%;
}


/* buttons css  */

.banner-button {
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  background: #d9481c; 
  color: white;
}
.banner-button-dark {
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  background: #d9481c;
  color: white;
}

/* Make sure the hover effect is targeting the button specifically */
.banner-button:hover {
  transform: scale(1.05);
  background-color: #000000; /* Dark red on hover */
  color: white;
}

.banner-button-dark:hover {
  transform: scale(1.05);
  background-color: white; /* Darker blue on hover */
  color: black;
}


/* DSA Essentials Button Styles */


/* button css end  */


.banner-light .container{
  background: linear-gradient(to right, white, #feb47b); /* Base gradient */
  color:black;
}
.banner-dark .container{
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #feb47b);
}

/* Right Section with Background Image */
.banner-right {
  min-width: 350px;
  height: auto;

  background-image: url('../assets/banner/setup2.png');
  background-size: cover;
}

/* Responsive Design */
@media (max-width: 768px) {
  .banner-right {
    min-height: 250px; 
  }
}


/* responsive design for banner buttons  */

/* Responsive Design */
@media (max-width: 1000px) {
  .banner-button {
    padding: 0.8rem 1rem;
    font-size: 0.9rem;
  }
  .banner-button-dark {
    padding: 0.8rem 1rem;
    font-size: 0.9rem;
  }
}
@media (max-width: 480px) {
  .banner-button {
    padding: 0.8rem 1rem;
    font-size: 0.8rem;
  }
  .banner-button-dark {
    padding: 0.8rem 1rem;
    font-size: 0.8rem;
  }
}

/* For smaller screens, set the image as a background */
@media (max-width: 767px) {
  .banner-right {
    background-image: url('../assets/banner/setup.jpeg');
  }
}

