/* Home.css */

.home {
    background-color: #f5f5f5;
    color: #333;
    min-height: 100vh;
    margin: 0 auto;
  }
  

/* In Home.css */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply this class to the page container */
.fade-in {
  animation: fadeInUp 1s ease-out forwards;
}
