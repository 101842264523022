/* RenderTags.css */
.tags-container {
    display: inline-flex; /* or flex if you want them to wrap */
    align-items: center; /* Vertically align items */
    gap: 5px; /* Add some spacing between tags */
}

.tag-pill {
    background-color: #eee; /* Light gray background */
    color: #333; /* Dark gray text */
    padding: 3px 8px;
    border-radius: 10px; /* Rounded corners */
    font-size: 0.8rem; /* Slightly smaller font */
    white-space: nowrap; /* Prevent tags from wrapping */
}

.tags-toggle-icon {
    cursor: pointer;
    margin-left: 5px; /* Space between last tag and icon */
    font-size: 0.8rem;
    color: #777; /* Slightly lighter gray for icon */
}

/* Dark theme styles */
.dark-theme .tag-pill {
    background-color: #333; /* Darker background in dark mode */
    color: #eee; /* Lighter text in dark mode */
}

.dark-theme .tags-toggle-icon {
    color: #aaa; /* Lighter gray for icon in dark mode */
}


/* css for tags - starting */
.tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .tag-pill {
    display: inline-block;
    padding: 0.2rem 0.5rem;
    background-color: #f1f1f1;
    border-radius: 15px;
    font-size: 0.9rem;
    color: #555;
    border: 1px solid #ddd;
    transition: all 0.3s ease;
    font-size: 0.7rem;
  }
  
  .tag-pill:hover {
    background-color: #ddd;
    color: #333;
    cursor: pointer;
  }
  
  .tags-toggle-icon {
    cursor: pointer;
    font-size: 14px;
    color: #d9481c;
    margin-left: 5px;
    transition: transform 0.2s ease-in-out;
  }
  
  .tags-toggle-icon:hover {
    color: #d9481c;
    transform: scale(1.1);
  }
  /* css for tags - end */
  
