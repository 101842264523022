.contact-page{
    background: linear-gradient(to top, #f9f9f9, white);
}


.link-hover {
    text-decoration: none; /* Default state */
    color: #0d6efd; /* Same as Bootstrap's "text-primary" for consistency */
}

.link-hover:hover {
    text-decoration: underline !important; /* Override any Bootstrap rules */
    color: #0056b3 !important; /* Your custom hover color */
}