/* General Navbar Styling */
.navbar {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  box-shadow: none;
  /* transition: all 0.3s ease-in-out; */
}

/* Light Theme */
.theme-light .navbar {
  background-color: #ffffff;
  color: #000;
}

.theme-light .navbar-nav .nav-link {
  color: #000 !important;
}

.theme-light .navbar-nav .nav-link:hover {
  color: #d9481c !important;
}

.theme-light .mentor-btn {
  background-color: #d9481c;
  color: #fff;
  border: 2px solid #d9481c;
}

.theme-light .mentor-btn:hover {
  background-color: #d9481c;
}

/* Dark Theme */
.theme-dark .navbar {
  background-color: #1a1a1a;
  color: #fff;
}

.theme-dark .navbar-nav .nav-link {
  color: #fff !important;
}

.theme-dark .navbar-nav .nav-link:hover {
  color: #d9481c !important;
}

.theme-dark .mentor-btn {
  background-color: #d9481c;
  color: #fff;
  border: 2px solid #d9481c;
}

.theme-dark .mentor-btn:hover {
  background-color: #d9481c;
}



/* Toggle Button Styling */
.navbar-toggler {
  padding: 0.4rem;
}

/* Navbar Links */
.navbar-nav {
  font-size: 1.1rem;
}

.navbar-nav .nav-link {
  font-weight: bold;
}

/* Active Tab Styling */
.navbar-nav .active-tab {
  border-bottom: 2px solid #d9481c;
  font-weight: bolder;
}

/* Theme Toggle Button */
.theme-toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  transition: color 0.3s ease-in-out;
}

.theme-light .theme-toggle-btn {
  color: black;
}

.theme-dark .theme-toggle-btn {
  color: white;
}

/* Mentor Button */
.mentor-btn {
  font-weight: bold;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 0.5rem 1.2rem;
}

.mentor-btn:hover {
  background-color: #333;
  color: #fff;
}

/* Login Button */
.navbar-nav .login-btn {
  color: #d9481c;
  font-weight: bold;
  border: 2px solid #d9481c;
  border-radius: 8px;
  padding: 0.3rem 1rem;
  background: none;
  transition: all 0.3s ease-in-out;
}

.navbar-nav .login-btn:hover {
  color: #d9481c;
  border-color: #d9481c;
}

/* Profile Image */
.profile-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}

/* Dropdown Menu */
.dropdown-menu-end {
  margin-bottom: 10px;
}

/* Mobile View Improvements */
@media (max-width: 992px) {
  .navbar {
    padding: 0.3rem 1rem;
  }

  .navbar-brand img {
    height: 40px;
  }

  .nav-item {
    padding-bottom: 1vh;
  }

  .navbar-nav .nav-link {
    padding: 0.4rem 0.6rem;
  }

  .nav-login {
    padding-top: 1vh;
    padding-bottom: 1vh;
  }
}
