.video-item:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }

.button:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(255, 0, 0, 0.612);
  }
  
.visitYoutubeButton{
    transform: scale(1.02);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}


.videos-light {
  background-color: #ffffff;
  color: #333;
}

.videos-dark {
  /* background-color: #181818; */
  background-color: black;
  color: white;
}

.videos-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.visitYoutubeButton{
  transition: transform 0.3s ease, background-color 0.3s ease;
  background-color: #E60000 !important;
}


.visitYoutubeButton:hover{
  transform: scale(1.05);
  background-color: #FF0000;
}