/* App.css */

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: auto;
}

.main-content {
  flex: 1;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.main-content-full {
  flex: 1;
  width: 100%; /* Full width for Banner */
  margin: 0;
  box-sizing: border-box;
}


.card:hover {
  transform: translateY(-1px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 0.7;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 1;
}

.dark{
  background-color: black;
}


.btn-codeharmony {
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  background: #d9481c !important; 
  color: white !important;
}
.btn-codeharmony-dark {
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  background: #d9481c !important;
  color: white !important;
}
.btn-codeharmony:hover {
  transform: scale(1.1);
  background-color: black !important;
}
.btn-codeharmony-dark:hover {
  transform: scale(1.1);
  background-color: white !important;
  color: black !important;
}