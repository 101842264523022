/* NotFound.css */

.not-found-container {
    text-align: center;
    padding: 3rem 1rem;
    color: #333;
    font-family: Arial, sans-serif;
  }
  
  .not-found-title {
    font-size: 6rem;
    font-weight: bold;
    margin-bottom: 1rem;
    /* color: #ff5252; */
    color:#d9481c;
  }
  
  .not-found-message {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #111;
  }
  
.dark .not-found-message {
  color: whitesmoke;
}

.back-home-link {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #d9481c;
  text-decoration: none;
  border-radius: 5px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  padding: 0.8rem 2rem;
}
  
.back-home-link:hover {
  background-color: black;
  transform: scale(1.1);
}

.dark .back-home-link:hover {
  background-color: white;
  color: black;
}
